
import * as ipxRuntime$JSWcVBDgSH from '/var/www/hima-trips/node_modules/@nuxt/image/dist/runtime/providers/ipx'

export const imageOptions = {
  "screens": {
    "xs": 320,
    "sm": 640,
    "md": 768,
    "lg": 1024,
    "xl": 1280,
    "xxl": 1536,
    "2xl": 1536
  },
  "presets": {},
  "provider": "ipx",
  "domains": [
    "biletaautobusi.com",
    "himaholidays.com"
  ],
  "alias": {
    "/extImages": "https://himaholidays.com/storage"
  },
  "densities": [
    1,
    2
  ],
  "format": [
    "webp"
  ],
  "quality": 70
}

imageOptions.providers = {
  ['ipx']: { provider: ipxRuntime$JSWcVBDgSH, defaults: {} }
}
        